import React from "react"
import styled from "styled-components"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer"

import colors from "../styles/colors"
import { Container } from "../styles/global"

import BarrasDeGomaDeGengibre from "../components/Produtos/BarrasDeGomaDeGengibre"
import BalasMaciasGengibre50g from "../components/Produtos/BalasMaciasGengibre50g"
import BebidaGaseirificadaABaseDeGengibre from "../components/Produtos/BebidaGaseirificadaABaseDeGengibre"
import CristaisGengibreEmTubo15g from "../components/Produtos/CristaisGengibreEmTubo15g"
import CristaisGengibreEmPote from "../components/Produtos/CristaisGengibreEmPote"
import GomasDeGengibreEmPote50g from "../components/Produtos/GomasDeGengibreEmPote50g"
import PuroExtratoDeGengibre60ml from "../components/Produtos/PuroExtratoDeGengibre60ml"
import GomasDeGengibre50g from "../components/Produtos/GomasDeGengibre50g"
import TemperosDeGengibre30g from "../components/Produtos/TemperosDeGengibre30g"

const SejaParceiro = () => (
  <>
    <SEO title="Seja um parceiro!" />
    <Header />
    <TopSection>
      <h1>Delícias naturais à base de gengibre para alegrar seu dia!</h1>
    </TopSection>
    <OuterContainer>
      <CardsContainer>
        <BalasMaciasGengibre50g />
        <BarrasDeGomaDeGengibre />
        <BebidaGaseirificadaABaseDeGengibre />
        <CristaisGengibreEmTubo15g />
        <CristaisGengibreEmPote />
        <GomasDeGengibreEmPote50g />
        <PuroExtratoDeGengibre60ml />
        <GomasDeGengibre50g />
        <TemperosDeGengibre30g />
      </CardsContainer>
    </OuterContainer>
    <Footer />
  </>
)

const OuterContainer = styled.div`
  background-color: ${colors.CINZA_CLARO};
`

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;

  h1 {
    margin-bottom: 30px;
    max-width: 500px;
    font-size: 36px;
    color: ${colors.VERDE_ESCURO};
    text-align: center;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 25px;
      max-width: 330px;
    }
  }
`

const CardsContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 1200px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`

export default SejaParceiro
