import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Card, CardImage, CardDescription } from "../styles"

import Image from "./image"
//import SemAcucar from "../../../images/Produtos/sem-acucar.svg"

const CristaisGengibreEmTubo15g = () => (
  <StyledLink to="/produtos/cristais-de-gengibre-em-tubo">
    <Card>
      <StyledCardImage>
        <Image />
        <p>6 Sabores</p>
      </StyledCardImage>
      <CardDescription>
        <h1>Cristais de gengibre em tubo | 15g</h1>
      </CardDescription>
    </Card>
  </StyledLink>
)

const StyledCardImage = styled(CardImage)`
  padding: 50px 95px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default CristaisGengibreEmTubo15g
