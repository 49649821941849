import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Card, CardImage, CardDescription } from "../styles"

import Image from "./image"
import SemAcucar from "../../../images/Produtos/sem-acucar.png"

const GomasDeGengibreEmPote50g = () => (
  <StyledLink to="/produtos/gomas-de-gengibre-em-pote">
    <Card>
      <StyledCardImage>
        <img src={SemAcucar} alt="Sem Açúcar" className="sem-acucar" />
        <Image />
        <p>3 sabores</p>
      </StyledCardImage>
      <CardDescription>
        <h1>Gomas de gengibre em pote | 50g</h1>
      </CardDescription>
    </Card>
  </StyledLink>
)

const StyledCardImage = styled(CardImage)`
  padding: 50px 85px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default GomasDeGengibreEmPote50g
