import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Card, CardImage, CardDescription } from "../styles"

import Image from "./image"

const CristaisGengibreEmPote = () => (
  <StyledLink to="/produtos/cristais-de-gengibre-em-pote">
    <Card>
      <CardImage>
        <Image />
        <p>6 Sabores</p>
      </CardImage>
      <CardDescription>
        <h1>Cristais de gengibre em disco | 8g e 10g</h1>
      </CardDescription>
    </Card>
  </StyledLink>
)

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default CristaisGengibreEmPote
