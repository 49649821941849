import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Card, CardImage, CardDescription } from "../styles"

import Image from "./image"
//import SemAcucar from "../../../images/Produtos/sem-acucar.svg"

const TemperosDeGengibre30g = () => (
  <StyledLink to="/produtos/tempero-de-gengibre">
    <Card>
      <CardImage>
        <Image />
        <p>5 sabores</p>
      </CardImage>
      <CardDescription>
        <h1>Temperos de gengibre | 30g</h1>
      </CardDescription>
    </Card>
  </StyledLink>
)

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default TemperosDeGengibre30g
