import styled from "styled-components"
import colors from "../../styles/colors"

export const Card = styled.div`
  width: 380px;
  height: 520px;

  margin: 7px;
  background-color: white;
  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:hover {
    border: 1px solid ${colors.VERDE};
  }

  @media (max-width: 400px) {
    height: auto;
    width: auto;
    margin: 7px 0;
  }
`
export const CardImage = styled.div`
  display: flex;
  align-items: center;

  height: 360px;
  padding: 50px 70px;
  position: relative;

  .sem-acucar {
    width: 94px;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 50;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  p {
    position: absolute;

    left: 40px;
    bottom: -16px;
    z-index: 500;

    background-color: white;
    padding: 6px 10px 2px 10px;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 105px;

    color: ${colors.CINZA_PADRAO};
  }

  @media (max-width: 400px) {
    height: auto;
  }
`

export const CardDescription = styled.div`
  padding: 40px 40px 20px 42px;
  height: 160px;
  overflow: hidden;
  border-top: 1.2px solid #dedede;

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    color: ${colors.PRETO_FOSCO};
  }
`
